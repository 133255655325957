import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import { HashRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <App />
        {/* <div style={{ height: "100vh", background: "#f3f4f6" }}>
          <h1
            style={{
              color: "#b91c1c",
              paddingTop: "50px",
              fontSize: "22px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Due to an unresolved payment issue, this website is temporarily
            unavailable
          </h1>
        </div> */}
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
